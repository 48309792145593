import { useCallback, useEffect, useRef, useState } from 'react';
import camelCaseKeys from 'camelcase-keys';
import queryString from 'query-string';

import api, { ApiError } from 'src/explore/services/api';

// FIXME: cannot import CancelToken and isCancel through services/api
import axios, { AxiosError, AxiosResponse, Canceler } from 'axios';

type IState<T = null> = {
  data: T | null;
  error: any; // FIXME: ApiError
  loading: boolean;
  meta: any;
  pristine: boolean;
  status: null | 'loading' | 'success' | 'canceled' | 'error';
};

type IOptions = {
  camelCaseKeys?: boolean;
  clearOnError?: boolean;
  delay?: boolean;
  params?: { [key: string]: string | null };
  responseParser?: ( response: any ) => Promise<any>;
  apiPathOverride?: string;
  appendHeader?: { [key: string]: string };
};

export type IApiObject<T> = IState<T> & {
  refetch: () => Canceler;
};

const INITIAL_STATE: IState = {
  data: null,
  error: null,
  loading: false,
  meta: null,
  pristine: true,
  status: null,
};

const DEFAULT_OPTIONS: IOptions = {
  delay: false,
  responseParser: ( response ) => Promise.resolve( response ),
};

export const useApiObject = <T extends Object>(
  url: string = '',
  options: Partial<IOptions> = {}
): IApiObject<T> => {
  const [ state, setState ] = useState<IState<T>>( INITIAL_STATE );
  const savedState = useRef<IState<T>>( state );
  const opts = useRef<IOptions>( DEFAULT_OPTIONS );

  // Remember latest state
  useEffect(() => {
    savedState.current = state;
  }, [ state ]);

  // Remember options
  useEffect(() => {
    opts.current = { ...opts.current, ...options };
  }, [ options ]);

  // Create query string from params
  const query = queryString.stringify( options.params || {}, { skipNull: true });

  // Function to fetch data
  const fetchData = useCallback(() => {
    if ( !url || options.delay ) return undefined;

    setState({
      ...savedState.current,
      loading: true,
      status: 'loading',
    });

    const source = axios.CancelToken.source();

    let apiParams: any;
    if ( options.apiPathOverride ) {
      apiParams = {
        params: opts.current.params,
        cancelToken: source.token,
        baseURL: options.apiPathOverride,
      };
    } else {
      apiParams = {
        params: opts.current.params,
        cancelToken: source.token,
      };
    }

    if ( options.appendHeader ) {
      apiParams.headers = options.appendHeader;
    }

    api
      .get( url, apiParams )
      .then(( response: AxiosResponse<any> ) => {
        const responseParser = (() => opts.current.responseParser( response.data ))();

        const responseParserPromise =
          typeof responseParser.then === 'function'
            ? responseParser
            : Promise.resolve( responseParser );

        responseParserPromise
          .then(( result ) => {
            if ( opts.current.camelCaseKeys ) {
              return camelCaseKeys( result, { deep: true });
            }

            return result;
          })
          .then(( result ) => {
            setState({
              ...savedState.current,
              data: result as T,
              error: null,
              loading: false,
              meta: response.data?.meta,
              pristine: false,
              status: 'success',
            });
          });
      })
      .catch(( error: AxiosError ) => {
        if ( axios.isCancel( error )) {
          setState({
            ...savedState.current,
            loading: false,
            pristine: false,
            status: 'canceled',
          });

          return;
        }

        const apiError = new ApiError( error.response );

        setState({
          ...savedState.current,
          data: opts.current.clearOnError ? null : savedState.current.data,
          error: apiError,
          loading: false,
          pristine: false,
          status: 'error',
        });
      });

    return source.cancel;
  }, [ url, query, options.delay ]);

  // Get new data
  useEffect(() => fetchData(), [ fetchData ]);

  return {
    ...state,
    refetch: fetchData,
  };
};

export { IState as IAPIObjectState };
