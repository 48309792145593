export const DEFAULT_ERROR =
  'Looks like our servers are having digestion problems. Try again later. If the problem persists, please contact our support team.';

export const FIVE_HUNDRED_ERROR =
  'Aww bananas cake, it looks that you have encountered a technical issue. Please reach out to our Customer Experience team through support@goldbelly.com or chat at the bottom right of our site';

class ApiError {
  code: number;

  data: any;

  errors: Array<any>;

  helpUrl: string;

  message: string;

  response: any;

  constructor( response: any ) {
    this.response = response;

    if ( response ) {
      this.code = response.status;
      this.message = this.code === 500 ? FIVE_HUNDRED_ERROR : response.message || DEFAULT_ERROR;

      const contentType = response?.headers ? response?.headers['content-type'] : undefined;
      if ( contentType && contentType.indexOf( 'application/json' ) !== -1 ) {
        this.code = response.data.status;
        this.data = response.data;
        this.message = response.data.message;
        this.helpUrl = response.data.help_url;
      }

      this.errors = this.parseErrors( response.data );
    }
  }

  hasErrors() {
    return !!this.errors;
  }

  parseErrors = ( data: any ) => {
    if ( data.errors && Object.keys( data.errors ).length > 0 ) {
      // FIXME: For now it's only base errors
      return this.getBaseErrors( data.errors );
    }
    if ( data.message ) {
      return [ data.message ];
    }
    return [ DEFAULT_ERROR ];
  };

  // Returns base errors including
  // associated models (but non recursive)
  getBaseErrors = ( errors: any ) => {
    const result: Array<any> = [];

    Object.keys( errors ).forEach(( key ) => {
      if ( key === 'base' || key.match( /(^|\.)base$/ )) {
        result.push( errors[key]);
      }
    });

    return result;
  };
}

export default ApiError;
